<template>
  <div id="cl-header">
    <v-navigation-drawer
      class="hidden-md-and-up header-one-page-nav-drawer demo-page-drawer"
      v-model="drawer"
      fixed
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img
            src="../../assets/images/logo/logo-symbol-dark.png"
            alt="brand-image"
          />
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        >
        </v-btn>
      </v-list-item>
      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
      >
        <v-list>
          <v-list-item
            :ripple="false"
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>

      <v-list>
        <!-- <v-list-item
          :ripple="false"
          link
          target="_blank"
          href="https://www.ibthemespro.com/docs/imroz/"
        >
          <v-list-item-content>
            <v-list-item-title>Documentation</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item
          :ripple="false"
          link
          target="_blank"
          href="https://support.rainbowit.net/support/login"
        >
          <v-list-item-content>
            <v-list-item-title>Friendly Support</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      elevation="0"
      color="#0000007a"
      absolute
      class="default-header preview-header"
      style="background-color: none;"
    >
      <router-link to="/" class="logo">
        <slot name="logo"></slot>
      </router-link>
      <!-- End brand logo -->
      <v-toolbar-items id="cl-toolbar" class="height-auto" style="margin: 0 auto; border-bottom: 1px solid white;padding-bottom: 0px;">
        <v-btn id="btnAgenda" @click="agenda = true" link :ripple="false" text>Agenda</v-btn>
        <v-btn id="btnRsvp" @click="rsvpmodel = true" link :ripple="false" text>RSVP Here</v-btn>
        <v-btn id="btnMap" @click="dialog = true" link :ripple="false" text>Venue Map</v-btn>
        <!-- <v-btn @click="video = true" link :ripple="false" text>Engagement Video</v-btn> -->    
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
          :offset="1"
        >        
          <v-btn
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            :ripple="false"
            text
            class="scrollactive-item"
            >{{ item.title }}</v-btn
          >
        </scrollactive>
      </v-toolbar-items>
      <!-- End header menu item -->
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->
    <v-dialog
      v-model="dialog"
      width="auto"
    >
      <v-card>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d106126.51807495565!2d18.841485637421282!3d-33.7747804732626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcda97505abaf5f%3A0xce783c25c031341d!2sBelair%20Pavilion!5e0!3m2!1sen!2sza!4v1708548004122!5m2!1sen!2sza" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <v-card-actions>
          <v-btn color="secondary" block @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="video"
      width="auto"
    >
      <!-- <v-card>
        <video id="myVideo" src="../../assets/videos/clvid.mp4" width="100%" height="40%" preload="auto" controls autoplay></video>
        <v-card-actions>
          <v-btn color="secondary" block @click="pauseVid()">Close</v-btn>
        </v-card-actions>
      </v-card> -->
    </v-dialog>

    <v-dialog
      v-model="agenda"
      width="auto"
    >
      <v-card>
        <v-img cover src="../../assets/images/agenda.png" :width="600"></v-img>
        <v-card-actions>
          <v-btn color="secondary" block @click="agenda = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rsvpmodel"
      width="auto"
    >
      <v-card width="800" min-height="400" class="px-5 py-5">
        <RsvpModal></RsvpModal>
        <v-card-actions>
          <v-btn color="secondary" block @click="rsvpmodel = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import feather from "feather-icons";
  import RsvpModal from "../modals/RsvpModal";
  export default {
    components: {
      RsvpModal
    },
    data: () => ({
      rsvpmodel: false,
      drawer: false,
      dialog: false,
      video: false,
      agenda: false,
      items: [
        // { title: "Venue Location" },
        // { title: "Function Details", to: "#view-demo" },
        // { title: "RSVP", to: "#feature" },
        // { title: "Gallery", to: "#faq" },
        // { title: "Accomadation", to: "#faq" },
        // { title: "Upload Pics", to: "#faq" },
      ],
      icon: "menu",
      closeIcon: "x",
    }),
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
      pauseVid() {
        this.video = false;
        let vid = document.getElementById("myVideo");
        vid.pause();
      }
    }
  };
</script>

<style lang="scss">
  @media only screen and (max-width: 767px) {
    .default-header a.btn-default {
      font-size: 14px;
      letter-spacing: 0;
      padding: 7px 16px;
    }
  }
  .demo-page-drawer {
    &.v-navigation-drawer--is-mobile .v-list {
      padding-bottom: 0 !important;
    }
  }
  .preview-header {
    &.v-toolbar:not(.tab-toolbar)
      .v-btn.v-btn.v-size--default:not(.btn-default) {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  .demo-page-drawer.v-navigation-drawer--is-mobile
    .v-list-item--link:last-child {
    border-bottom: 1px solid var(--color-border);
  }

  #btnAgenda:hover, #btnRsvp:hover, #btnMap:hover {
    color: rgb(235, 229, 229) !important;
  }
</style>
