<template>
  <div class="">
    <v-row>
      <!-- DESKTOP BEGIN -->
      <v-col id="left" cols="6" class="hidden-xs-only">
        <v-carousel height="auto" show-arrows="false" hide-delimiters cycle>
          <v-carousel-item src="../assets/images/bg/clps1.png" cover></v-carousel-item>

          <v-carousel-item src="../assets/images/bg/clps2.png" cover></v-carousel-item>

          <v-carousel-item src="../assets/images/bg/clps3.png" cover></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col id="right" class="hidden-xs-only active-dark">
        
        <PreviewHeader></PreviewHeader>        

        <div class="pv-slider-area slider-wrapper">
          <div class="slider-activation">
            <div class="slide slide-style-1 slider-fixed--height d-flex align-center cl-bg-image bg_image bg_image--34"
              data-black-overlay="6">
              <div class="container position-relative mt-5" style="margin-bottom: 50px;">
                <div class="row">
                  <div class="col-lg-12">
                    <div style="text-align: center;">
                      <h3 class="old-standard tt-regular hidden-xs-only" style="font-size: 50px;margin-top: 120px;">
                        You are invited
                      </h3>
                      <h3 class="old-standard tt-regular hidden-md-and-up" style="font-size: 20px;margin-top: 40px;">
                        You are invited <br> to attend the wedding of
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="" style="text-align: center;">
                      <h3 class="old-standard tt-regular hidden-xs-only"
                        style="font-size: 20px;margin-bottom: 20px;margin-top: 10px;">
                        to attend the wedding of
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="row hidden-xs-only" style="margin-top: -10px;">
                  <div class="col-lg-12">
                    <div class="inner" style="padding-top: 0px; margin-bottom: 0px; display">
                      <h1 class="whisper-regular take-it-up" style="margin-bottom:0px">
                        Lauren &amp; Christopher
                      </h1>
                    </div>
                  </div>
                </div>
                <div class="row hidden-md-and-up" style="margin-top: 5px; display: block; padding-top: 15px;">
                  <div class="">
                    <div class="inner" style="padding-top: 0px;">
                      <h2 class="whisper-small">
                        Lauren &amp; Chris
                      </h2>
                    </div>
                  </div>
                </div>

                <div class="service-wrapper service-white" style="margin-top: 0px;">

                  <v-row class="row--25">
                    <!-- Start Single Service  -->
                    <v-col cols="12" v-for="(service, i) in serviceContent" :key="i">
                      <div class="service service__style--1" style="margin-top: 10px;" v-if="service.title">
                        <div class="content">
                          <h2 class="old-standard tt-regular" style="font-size: 18px">
                            {{ service.title }} {{ service.time }}
                          </h2>
                          <h2 style="font-size: 18px;color: aliceblue;" class="old-standard tt-regular">
                            {{ service.desc }}
                          </h2>
                          <p style="font-size: 18px;color: aliceblue;" class="old-standard tt-regular">
                            {{ service.dresscode }}
                          </p>
                          <p style="font-size: 16px;color: aliceblue;" class="old-standard tt-regular">
                            {{ service.gift }}<br />{{ service.gift2 }}
                          </p>
                        </div>
                      </div>
                    </v-col>
                    <!-- End Single Service  -->
                  </v-row>
                  <v-row class="mt-12">
                    <v-col cols="12">
                      <div class="service service__style--1" style="margin-top: 0px;">
                        <div class="content">
                          <flip-countdown deadline="2024-10-13 14:30:00"></flip-countdown>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-12">
                    <v-col cols="2"></v-col>
                    <v-col cols="">
                      <div class="content" style="text-align: center;">
                        <v-btn x-large @click="rsvpmodel = true" link :ripple="false">RSVP Here</v-btn>
                      </div>
                    </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Banner Area  -->

        <!-- Start Main Demo Area  -->
        
        <!-- End Main Demo Area  -->
        <v-dialog v-model="rsvpmodel" width="auto">
          <v-card width="800" min-height="400" class="px-5 py-5">
            <RsvpModal></RsvpModal>
            <v-card-actions>
              <v-btn color="secondary" block @click="rsvpmodel = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <!-- DESKTOP END -->

      <!-- MOBILE BEGIN-->
      <v-col id="right" class="hidden-md-and-up">
        <PreviewHeader></PreviewHeader> 

        <div class="pv-slider-area slider-wrapper">
          <div class="slider-activation">
            <div class="row hidden-md-and-up" style="display: block;">
                  <div class="">
                    <div class="inner" style="padding-top: 78px;">
                      <h2 class="whisper-small black-text">
                        Lauren &amp; Chris
                      </h2>
                    </div>
                  </div>
                </div>
            <div class="slide slide-style-1 slider-fixed--height d-flex align-center"
              >
              <div class="container position-relative" style="margin-bottom: 18px;">

                

                <div class="row black-bg-white-text">
                  <div class="col-lg-12">
                    <div style="text-align: center;">
                      <h3 class="old-standard tt-regular white-text" style="font-size: 20px; margin-bottom: 5px; padding-top: 10px;">
                        You're invited to our wedding
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="service-wrapper" style="margin-top: 0px;">

                  <v-row class="row--25 black-bg-white-text">
                    <!-- Start Single Service  -->
                    <v-col cols="12" v-for="(service, i) in serviceContent" :key="i">
                      <div class="service service__style--1" style="margin-top: 20px;" v-if="service.title">
                        <div class="content">
                          <h2 class="old-standard tt-regular white-text" style="font-size: 18px">
                            {{ service.title }} {{ service.time }}
                          </h2>
                          <h2 style="font-size: 18px;" class="old-standard tt-regular white-text">
                            {{ service.desc }}
                          </h2>
                          <p style="font-size: 16px; margin-top: 20px; margin-bottom: 10px;" class="old-standard tt-regular white-text">
                            {{ service.dresscode }}
                          </p>
                          <span style="font-size: 16px; font-style: italic; display: flex; justify-content: center; margin-bottom: 0px; margin-top: 0px;" class="old-standard tt-regular white-text">
                            {{ service.gift }}<br/>{{ service.gift2 }}
                          </span>
                        </div>
                      </div>
                    </v-col>
                    <!-- End Single Service  -->
                  </v-row>

                  <v-row class="black-bg-white-text">
                    <v-col cols="12">
                      <div class="service service__style--1" style="margin-top: 20px;">
                        <div class="content">
                          <flip-countdown deadline="2024-10-13 14:30:00"></flip-countdown>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row class="black-bg-white-text">
                    <v-col cols="1"></v-col>
                    <v-col cols="">
                      <div class="content" style="text-align: center; padding: 30px 0 50px 0;">
                        <v-btn style="" x-large @click="rsvpmodel = true" link :ripple="false">RSVP Here</v-btn>
                      </div>
                    </v-col>
                    <v-col cols="1"></v-col>
                  </v-row>

                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <!-- MOBILE END-->
      
    </v-row>

  </div>
  <!-- End Page Wrapper  -->
</template>

<script>
  import feather from "feather-icons";
  import PreviewHeader from "../components/header/PreviewHeader";
  import FlipCountdown from "vue2-flip-countdown";
  import RsvpModal from "../components/modals/RsvpModal";
  export default {
    components: {
      PreviewHeader,
      FlipCountdown,
      RsvpModal
    },
    name: "Home",
    data() {
      return {
        rsvpmodel: false,
        logo: require("../assets/images/logo/logo.png"),
        tab: null,
        tabItems: [
          {
            id: 1,
            name: "All Demo",
          },
          {
            id: 2,
            name: "Agency",
          },
          {
            id: 3,
            name: "Corporate",
          },
          {
            id: 4,
            name: "Portfolio",
          },
          {
            id: 5,
            name: "Landing",
          },
        ],
        tabContent: [
          {
            id: 1,
            content: [
              {
                src: require("../assets/images/preview/demo-home.png"),
                alt: "Main Demo",
                title: "Main Demo",
                href: "/main-demo",
                active: false,
              },
              {
                src: require("../assets/images/preview/dark-main-demo.png"),
                title: "Main Demo-Dark Version",
                alt: "Main Demo Dark",
                href: "/main-demo-dark",
                active: false,
              },
              {
                src: require("../assets/images/preview/personal-portfolio.png"),
                title: "Personal Portfolio",
                alt: "Personal Portfolio",
                href: "/personal-portfolio",
                sticker: "Hot",
                active: true,
              },
              {
                src: require("../assets/images/preview/interior-design.png"),
                title: "Interior Design",
                alt: "Interior Design",
                href: "/landing-interior",
                sticker: "Hot",
                active: true,
              },
              {
                src: require("../assets/images/preview/dark-personal-portfolio-landing.png"),
                title: "Personal Portfolio Dark",
                alt: "Personal Portfolio Dark",
                href: "/landing-dark-portfolio",
                sticker: "New",
                active: false,
              },
              {
                src: require("../assets/images/preview/portfolio-home.png"),
                title: "Minimal Portfolio",
                alt: "Minimal Portfolio",
                href: "/minimal-portfolio",
                active: false,
              },
              {
                src: require("../assets/images/preview/digital-agency.png"),
                title: "Digital Agency",
                alt: "Digital Agency",
                href: "/digital-agency",
                active: false,
              },
              {
                src: require("../assets/images/preview/creative-agency.png"),
                title: "Creative Agency",
                alt: "Creative Agency Images",
                href: "/creative-agency",
                active: false,
              },
              {
                src: require("../assets/images/preview/corporate-business.png"),
                title: "Corporate Business",
                alt: "Corporate Business",
                href: "/corporate-business",
                sticker: "New",
                active: false,
              },
              {
                src: require("../assets/images/preview/personal-portfolio-landing.png"),
                title: "Personal Portfolio — One Page",
                alt: "Personal Portfolio — One Page",
                href: "/landing-personal-portfolio",
                sticker: "Hot",
                active: true,
              },
              {
                src: require("../assets/images/preview/creative-landing.png"),
                title: "Creative Agency 02-One Page",
                alt: "Creative Agency 02",
                href: "/landing-creative-agency",
                active: false,
              },
              {
                src: require("../assets/images/preview/business.png"),
                title: "Business",
                alt: "Business",
                href: "/business",
                active: false,
              },
              {
                src: require("../assets/images/preview/designer-portfolio.png"),
                title: "Designer Portfolio",
                alt: "Designer Portfolio",
                href: "/designer-portfolio",
                sticker: "Hot",
                active: false,
              },
              {
                src: require("../assets/images/preview/homeparticles.png"),
                title: "Home Particle - Landing Page",
                alt: "Home Particle",
                href: "/landing-home-particle",
                active: false,
              },
              {
                src: require("../assets/images/preview/studio-agency.png"),
                title: "Studio Agency",
                alt: "Studio Agency",
                href: "studio-agency",
                active: false,
              },
              {
                src: require("../assets/images/preview/startup.png"),
                title: "Startup",
                alt: "Startup",
                href: "/startup",
                active: false,
              },
              {
                src: require("../assets/images/preview/creative-portfolio.png"),
                title: "Creative Portfolio",
                alt: "Creative Portfolio",
                href: "/creative-portfolio",
                sticker: "New",
                active: false,
              },
              {
                src: require("../assets/images/preview/coming-soon.png"),
                title: "Coming Soon",
                alt: "Coming Soon",
                desc: `More Feature are Coming Soon`,
                href: "/",
                active: false,
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                src: require("../assets/images/preview/demo-home.png"),
                alt: "Main Demo",
                title: "Main Demo",
                href: "/main-demo",
                active: false,
              },
              {
                src: require("../assets/images/preview/creative-agency.png"),
                title: "Creative Agency",
                alt: "Creative Agency Images",
                href: "/creative-agency",
                active: false,
              },
              {
                src: require("../assets/images/preview/corporate-business.png"),
                title: "Creative Agency",
                alt: "Creative Agency",
                href: "/corporate-business",
                sticker: "New",
                active: true,
              },
              {
                src: require("../assets/images/preview/homeparticles.png"),
                title: "Home Particle - Landing Page",
                alt: "Home Particle",
                href: "/landing-home-particle",
                active: false,
              },
              {
                src: require("../assets/images/preview/digital-agency.png"),
                title: "Digital Agency",
                alt: "Digital Agency",
                href: "/digital-agency",
                active: false,
              },

              {
                src: require("../assets/images/preview/creative-portfolio.png"),
                title: "Creative Portfolio",
                alt: "Creative Portfolio",
                href: "/creative-portfolio",
                sticker: "New",
                active: false,
              },
              {
                src: require("../assets/images/preview/studio-agency.png"),
                title: "Studio Agency",
                alt: "Studio Agency",
                href: "studio-agency",
                active: false,
              },
              {
                src: require("../assets/images/preview/dark-main-demo.png"),
                title: "Main Demo-Dark Version",
                alt: "Main Demo Dark",
                href: "/main-demo-dark",
                active: false,
              },
              {
                src: require("../assets/images/preview/creative-landing.png"),
                title: "Creative Agency 02-One Page",
                alt: "Creative Agency 02",
                href: "/landing-creative-agency",
                active: false,
              },
              {
                src: require("../assets/images/preview/interior-design.png"),
                title: "Interior Design",
                alt: "Interior Design",
                href: "/landing-interior",
                sticker: "Hot",
                active: true,
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                src: require("../assets/images/preview/business.png"),
                title: "Business",
                alt: "Business",
                href: "/business",
                active: false,
              },
              {
                src: require("../assets/images/preview/startup.png"),
                title: "Startup",
                alt: "Startup",
                href: "/startup",
                active: false,
              },
              {
                src: require("../assets/images/preview/corporate-business.png"),
                title: "Creative Agency",
                alt: "Creative Agency",
                href: "/corporate-business",
                sticker: "New",
                active: true,
              },
              {
                src: require("../assets/images/preview/creative-portfolio.png"),
                title: "Creative Portfolio",
                alt: "Creative Portfolio",
                href: "/creative-portfolio",
                sticker: "New",
                active: true,
              },
              {
                src: require("../assets/images/preview/homeparticles.png"),
                title: "Home Particle",
                alt: "Home Particle",
                href: "/landing-home-particle",
                active: false,
              },

              {
                src: require("../assets/images/preview/studio-agency.png"),
                title: "Studio Agency",
                alt: "Studio Agency",
                href: "studio-agency",
                active: false,
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                src: require("../assets/images/preview/personal-portfolio.png"),
                title: "Personal Portfolio",
                alt: "Personal Portfolio",
                href: "/personal-portfolio",
                sticker: "New",
                active: true,
              },
              {
                src: require("../assets/images/preview/dark-personal-portfolio-landing.png"),
                title: "Personal Portfolio 02 — One Page",
                alt: "Personal Portfolio 02 — One Page",
                href: "/landing-dark-portfolio",
                sticker: "New",
                active: false,
              },
              {
                src: require("../assets/images/preview/designer-portfolio.png"),
                title: "Designer Portfolio",
                alt: "Designer Portfolio",
                href: "/designer-portfolio",
                sticker: "Hot",
                active: false,
              },

              {
                src: require("../assets/images/preview/portfolio-home.png"),
                title: "Minimal Portfolio",
                alt: "Minimal Portfolio",
                href: "/minimal-portfolio",
                active: false,
              },
              {
                src: require("../assets/images/preview/creative-portfolio.png"),
                title: "Creative Portfolio",
                alt: "Creative Portfolio",
                href: "/creative-portfolio",
                sticker: "New",
                active: true,
              },
              {
                src: require("../assets/images/preview/personal-portfolio-landing.png"),
                title: "Personal Portfolio — One Page",
                alt: "Personal Portfolio — One Page",
                href: "/landing-personal-portfolio",
                active: false,
              },
            ],
          },
          {
            id: 5,
            content: [
              {
                src: require("../assets/images/preview/dark-personal-portfolio-landing.png"),
                title: "Personal Portfolio 02 — One Page",
                alt: "Personal Portfolio 02 — One Page",
                href: "/landing-dark-portfolio",
                sticker: "New",
                active: false,
              },
              {
                src: require("../assets/images/preview/personal-portfolio-landing.png"),
                title: "Personal Portfolio — One Page",
                alt: "Personal Portfolio — One Page",
                href: "/landing-personal-portfolio",
                sticker: "Hot",
                active: true,
              },
              {
                src: require("../assets/images/preview/creative-landing.png"),
                title: "Creative Agency 02-One Page",
                alt: "Creative Agency 02",
                href: "/landing-creative-agency",
                active: false,
              },
              {
                src: require("../assets/images/preview/interior-design.png"),
                title: "Interior Design",
                alt: "Interior Design",
                href: "/landing-interior",
                sticker: "Hot",
                active: true,
              },
              {
                src: require("../assets/images/preview/homeparticles.png"),
                title: "Home Particle",
                alt: "Home Particle",
                href: "/landing-home-particle",
                active: false,
              },
            ],
          },
        ],
        innerPages: [
          {
            src: require("../assets/images/preview/service.png"),
            title: "Service",
            href: "/service",
            alt: "Service",
          },
          {
            src: require("../assets/images/preview/service-details.png"),
            title: "Service Details",
            href: "/service-details",
            alt: "Service Details",
          },
          {
            src: require("../assets/images/preview/about.png"),
            title: "About",
            href: "/about",
            alt: "About",
          },
          {
            src: require("../assets/images/preview/blog-list.png"),
            title: "Blog List",
            href: "/blog",
            alt: "Blog List",
          },
          {
            src: require("../assets/images/preview/blog-details.png"),
            title: "Blog Details",
            href: "/blog-details",
            alt: "Blog Details",
          },
          {
            src: require("../assets/images/preview/portfolio.png"),
            title: "Portfolio",
            href: "/portfolio",
            alt: "Portfolio",
          },
          {
            src: require("../assets/images/preview/portfolio-details.png"),
            title: "Portfolio Details",
            href: "/portfolio-details",
            alt: "Portfolio Details",
          },
          {
            src: require("../assets/images/preview/video-popup.png"),
            title: "Video Popup",
            href: "/video-popup",
            alt: "video popup",
          },
          {
            src: require("../assets/images/preview/contact.png"),
            title: "Contact",
            href: "/contact",
            alt: "contact",
          },
        ],
        panel: 0,
        featuresContent: [
          {
            icon: "check-square",
            title: "VueJS",
            desc: ` No.1 Github Start & Developer Friendly Top Progressive JavaScript Framework `,
          },
          {
            icon: "cast",
            title: "Vuetify Js",
            desc: `It's a complete UI framework built with Vue.js which you get rich user experiences`,
          },
          {
            icon: "smartphone",
            title: "Perfect Responsive",
            desc: `Vuetify takes a mobile first approach to design like phone, tablet, or desktop computer.`,
          },
          {
            icon: "command",
            title: "Sass Available",
            desc: ` The tamplate has Sass available for css. You can Change
                        css by sass`,
          },
          {
            icon: "cpu",
            title: "Fast Loading Speed",
            desc: `Trydo is faster loading speed.Trydo create your theme so
                        much faster way`,
          },
          {
            icon: "file-plus",
            title: "Well Documented Codes",
            desc: `The Trydo code is awesome well documented code. And Its
                        customization is very easily`,
          },
          {
            icon: "framer",
            title: "Modern Design",
            desc: `Trydo is a modern creatuve design for Creative Agency ,
                        Personal Portfolio etc....`,
          },
          {
            icon: "headphones",
            title: "24 Support System",
            desc: `We are provide 24 hours support for all clients.You can
                        purchase without hesitation.`,
          },
        ],
        serviceContent: [
          {
            icon: "",
            title: "",
            desc: "",
            time: "",
            dresscode: "",
            gift: "",
            gift2: "",
          },
          {
            icon: "cast",
            title: "Sunday, 13 October 2024",
            desc: "Belair Pavilion - Paarl",
            time: " - 2:30 PM",
            dresscode: "Dress Code: Formal (No Jeans/Sneakers)",
            gift: "Celebrating with you is what matters most",
            gift2: "Any gift, big or small, is truly appreciated",
          },
          {
            icon: "",
            title: "",
            desc: "",
            time: "",
            dresscode: "",
            gift: "",
            gift2: "",
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .black-bg-white-text {
    background-color: rgba(0, 0, 0, 0.400);
    color: aliceblue !important;
  }
  .white-text {
    color: aliceblue;
    text-align: center;
  }
  .black-text {
    color: rgb(0, 0, 0);
    text-align: center;
  }
  .theme--light.about-expension-panels .v-expansion-panel-header::after {
    height: 1px;
    width: 100%;
  }
  .theme--light.about-expension-panels .v-expansion-panel-header {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 0 10px 0;
    font-size: 18px;
  }
  .main-page-preview-gallery {
    .v-tabs {
      margin: 35px auto 30px;
    }
  }
  .v-expansion-panel-content__wrap {
    p {
      a {
        font-weight: 500;
      }
    }
  }
  .new-inner {
    text-align: center;
  }
</style>
<style lang="scss">

  .header-area .logo a img {
    max-height: 60px;
  }
  .faq-area {
    strong {
      color: var(--color-primary);
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 767px){
    .home-demo-area.inner-page-demo{
      .single-demo{
        padding: 0;
      }
      .wrapper{
        padding: 0 20px !important;
      }
    }
    .prv-feature {
      .plr_sm--30 {
          padding: 0 20px !important;
      }
    }
  }

  // .take-it-up {
  //   margin-bottom: 50px;
  // }

  // .cl-bg-image {
  //   background-position: 100% 100% !important;
  // }

  html { 
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

</style>
