<template>
    <div>
        <v-card
            class="mx-auto mx-5"
            color="blue-grey-darken-1"
            max-width="800"
        >
            <v-img
                height="150"
                src="https://cdn.vuetifyjs.com/images/cards/dark-beach.jpg"
                cover
            >
                <v-row class="pa-3">
                    <v-col class="text-center">
                        <span class="text-h3 white--text">RSVP</span>
                        <br/>
                        <span class="text-h5 white--text">to the Wedding of Lauren & Chris</span>
                    </v-col>
                </v-row>
            </v-img>
            <v-progress-linear :indeterminate="true" v-if="loading2" color="black"></v-progress-linear>
            <v-form>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-autocomplete     
                clearable
                filled
                :items="allPeople"
                item-text="name"
                item-value="id"
                return-object
                v-model="selectedPerson"         
                label="Name"
                style="border:none;"
                class="mt-5 rsvp-form override"
                @change="onAutoCompleteChanged()"
                color="black"
            >                            
            </v-autocomplete>
          </v-col>
        </v-row>

        <table width="100%" v-if="this.selectedPerson && this.selectedPerson.id">
            <tr>
                <th>Name</th>
                <th>RSVP</th>
            </tr>          
            <tr v-for="person in people" :key="person.id">
                <td>{{person.name}}</td>
                <td style="text-align: center;">
                    <v-btn-toggle
                        rounded
                        v-model="person.isAttending"
                    >
                        <v-btn small active-class="red" :value="0">
                            No
                        </v-btn>
                        <v-btn small active-class="green" :value="1">
                            Yes
                        </v-btn>
                    </v-btn-toggle>
                </td>
            </tr>
        </table>

        <hr class="my-5" v-if="this.selectedPerson && this.selectedPerson.id"/>

        <v-row v-if="this.selectedPerson && this.selectedPerson.id">
            <v-btn btn-large class="mb-5 mx-auto" @click="SaveRsvps()">Save</v-btn>
        </v-row>

        <v-progress-linear :indeterminate="true" v-if="loading" color="black"></v-progress-linear>
      </v-container>
    </v-form>

    </v-card>
    <v-snackbar
      v-model="snackbar"
      color="success"
      :multi-line="true"
      :timeout="6000"
    >
      Thank you. Your RSVP has been saved.
      <v-btn
        dark
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    </div>    
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        loading2: false,
        snackbar: false,
        allPeople: null,
        people: null,
        selectedPerson: {id: null, name: null, houseHoldId: null}
      };
    },
    mounted() {
        this.loading2 = true;
        this.selectedPerson = {id: null, name: null, houseHoldId: null};
        this.getRsvpData();
    },
    methods: {
      onAutoCompleteChanged() {
        if(this.selectedPerson !== null) {
            this.people = this.allPeople.filter(ap => ap.houseHoldId === this.selectedPerson.houseHoldId);
        }
      },
      async getRsvpData() {
        //const response = await fetch("https://localhost:7222/api/rsvp");
        const response = await fetch("https://weddingapi20240825112030.azurewebsites.net/api/rsvp");
        
        const result = response.json();
        console.log(result);
        result.then(d => {
            this.allPeople = d.rsvps.sort((a, b) => a.name.localeCompare(b.name));
            this.people = d.rsvps;
            this.loading2 = false;
        })
      },
      async SaveRsvps() {       
        this.loading = true;
        let requestData = this.people.map(x => ({ id: x.id, isAttending: x.isAttending}));
        console.log(JSON.stringify(requestData));

        // const response = await fetch("https://localhost:7222/api/rsvp", {
        const response = await fetch("https://weddingapi20240825112030.azurewebsites.net/api/rsvp", {
            method: "POST",
            body: `{"rsvps": ${JSON.stringify(requestData)}}`, // JSON.stringify(requestData)
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(res => {
            console.log(res);
            this.snackbar = true;
            this.loading = false;
        });
      }
    },
  };
</script>

<style>
    .rsvp-form input {
        border: none;
    }
    table, th, td {
        padding: 5px 10px 5px 10px;
        border: 1px solid black;
        border-collapse: collapse;
        border-color: #e1e4e4;
    }
    .red {
        background-color: rgb(223, 91, 91);
    }
    .green {
        background-color: rgb(105, 206, 105);
    }
    .v-select__selection,
    .v-select__selection--comma,
    .v-select.v-text-field input {
        color: rgb(0, 0, 0) !important;
    }
    .v-menu__content .v-list-item:hover .v-list-item__title {
        color: #000000 !important;
    }
</style>